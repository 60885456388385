import {motion} from 'framer-motion';
import React from 'react';
import {FaGithub, FaLinkedin} from 'react-icons/fa';
import {TypeAnimation} from 'react-type-animation';
import Image from '../assets/ibrahim-circle.png';
import {fadeIn} from '../variants';

const Banner = () => {
  return (
    <section
      id="home"
      className=" py-8 min-h-screen flex items-center lg:mt-10">
      <div className="container mx-auto">
        <div
          className="flex flex-col gap-y-8 lg:flex-row lg:items-center
        lg:gap-x-12">
          <div className="flex-1 text-center font-secondary lg:text-left ">
            {/* <span className="pl-1 text-accent font-semibold ">
              Hi, my name is{' '}
            </span> */}
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{once: false, amount: 0.7}}
              className="text-[50px] font-bold  leading-[0.8] lg:text-[100px] lg:text-[90px] ">
              IBRAHIM <span>UYAR</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)}
              initial="hidden"
              whileInView={'show'}
              viewport={{once: false, amount: 0.7}}
              className="mb-6 text-[36px] lg:text-[55px] font-secondary font-semibold uppercase leading-[1]">
              {/* <span className="mr-4 text-white">I am a</span> */}
              <TypeAnimation
                sequence={['Android Developer!', 2000, 'Kotlin!', 2000]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView={'show'}
              viewport={{once: false, amount: 0.7}}
              className="mb-8 max-w-lg mx-auto lg:mx-0">
              Hey there! I'm an Android Developer with a passion for creating
              innovative mobile apps that make people's lives easier. From
              designing intuitive user interfaces to implementing complex
              functionalities, I bring a creative and detail-oriented approach
              to every project. Let's work together to create something amazing!
            </motion.p>
            <motion.div
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView={'show'}
              viewport={{once: false, amount: 0.7}}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0">
              <a className="" href="mailto:iuyar99@gmail.com" target="blank">
                <button className="btn btn-sm transition-all duration-500">
                  Contact me
                </button>
              </a>
            </motion.div>
            <motion.div
              variants={fadeIn('up', 0.6)}
              initial="hidden"
              whileInView={'show'}
              viewport={{once: false, amount: 0.7}}
              className="flex text-[20px] gap-x-8 max-w-max mx-auto lg:mx-0 mt-10">
              <a
                href="https://github.com/uyaribrahim"
                target="blank"
                className="transform hover:rotate-45 transition duration-500 hover:text-accent">
                <FaGithub />
              </a>
              {/* <a
                href="#"
                target="blank"
                className="transform hover:rotate-45 transition duration-500 hover:text-accent">
                <FaInstagram />
              </a> */}
              <a
                href="https://www.linkedin.com/in/ibrhmuyar/"
                target="blank"
                className="transform hover:rotate-45 transition duration-500 hover:text-accent">
                <FaLinkedin />
              </a>
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn('down', 0.5)}
            initial="hidden"
            whileInView={'show'}
            className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] p-20">
            <img className="rounded-full boxShadow" src={Image}></img>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
