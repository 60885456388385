import {motion} from 'framer-motion';
import React from 'react';
// import Img2 from '../assets/portfolio-img2.png';
// import Img3 from '../assets/portfolio-img3.png';
import {projects} from '../utils/projects';
import {fadeIn} from '../variants';

const Work = () => {
  return (
    <section id="work" className="section h-max lg:items-center ">
      <div className="container mx-auto ">
        <div className="flex flex-col  ">
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{once: false, amount: 0.2}}
            className="flex-1 flex flex-col gap-y-8 mb-10 lg:mb-0">
            {/* text */}
            <div>
              <h2 className="font-primary text-[32px] mb-6 tracking-[10%] leading-tight text-accent after:bg-accent after:h-px lg:after:w-[300px] after:inline-block after:relative after:w-full lg:after:ml-4 after:mb-2">
                Some Things I’ve Built
              </h2>
              <p className="max-w-lg  mb-6">
                Hello and welcome to my portfolio! As an Android developer, I've
                had the opportunity to work on a wide range of projects, and I'm
                excited to showcase some of them here. In this section, you'll
                find examples of my work, including mobile applications that
                I've designed and developed. I'm proud of the solutions I've
                created and the impact they've had on the users who use them.
              </p>
              <p className="max-w-lg  mb-10">
                Take some time to browse through my portfolio, and if you have
                any questions or are interested in working together, feel free
                to reach out. I'd love to hear from you and discuss any
                potential projects you have in mind.
              </p>
              <a href="https://github.com/uyaribrahim" target="blank">
                <button className="btn btn-sm transition-all duration-500">
                  View all projects
                </button>
              </a>
            </div>
            {/* image */}
          </motion.div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-6 gap-x-5 mt-10">
            {projects.map((project, index) => (
              <></>
              // <motion.div
              //   variants={
              //     index % 2 === 0 ? fadeIn('right', 0.3) : fadeIn('left', 0.3)
              //   }
              //   initial="hidden"
              //   whileInView={'show'}
              //   viewport={{once: false, amount: 0.2}}
              //   className="max-w-[570px] lg:max-h-[340px] max-h-[200px] grid-item group relative overflow-hidden border-2 border-black/30 rounded-xl ">
              //   <div className="group-hover:bg-black/70 bg-accent/10 w-full  h-full absolute z-40 transition-all duration-300"></div>
              //   <img
              //     className="object-fill group-hover:scale-125 transition-all duration-500 "
              //     src={project.image}
              //     alt=""
              //   />

              //   <div className="absolute -bottom-full left-8 group-hover:bottom-20 transition-all duration-500 z-50">
              //     <span className="text-gradient">{project.title}</span>
              //   </div>
              //   <div className="absolute -bottom-full left-8 group-hover:bottom-10 transition-all duration-700 z-50">
              //     <span className="text-3xl text-white h2 text-[26px] lg:text-[32px]">
              //       {project.name}
              //     </span>
              //   </div>
              //   <div className="hidden xl:flex absolute -bottom-full left-8 group-hover:bottom-36  transition-all duration-500 z-50">
              //     <p className="max-w-md text-left leading-[1.5] line-clamp-5 font-medium">
              //       {project.content}
              //     </p>
              //   </div>
              // </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
