import {motion} from 'framer-motion';
import React from 'react';
import {fadeIn} from '../variants';

const Contact = () => {
  return (
    <div id="contact" className="section min-h-screen ">
      <div className="flex flex-col container mx-auto items-center lg:mt-20 ">
        <h2 className=" font-primary text-[32px] text-accent  mb-8">
          Get In Touch
        </h2>
        <p className="max-w-xl text-center mb-4">
          Thanks for taking the time to visit my website! If you'd like to get
          in touch, please don't hesitate to send me an email at
          iuyar99@gmail.com. Simply click the 'Contact Me' button below, and
          your email client will open with my address pre-filled.
        </p>
        <p className="max-w-xl text-center leading-[2] mb-4">
          Whether you have a question, a project you'd like to discuss, or just
          want to say hello, I'd love to hear from you. I do my best to respond
          to all inquiries within 24-48 hours.{' '}
        </p>
        <p className="max-w-xl text-center leading-[2] lg:mb-10 mb-4">
          Thanks again for visiting, and I look forward to hearing from you!
        </p>
        <motion.div
          variants={fadeIn('up', 0.3)}
          initial="hidden"
          whileInView={'show'}
          viewport={{once: false, amount: 0.3}}
          className="flex max-w-max items-center mb-12 mx-auto">
          <a className="" href="mailto:iuyar99@gmail.com" target="blank">
            <button className="btn btn-sm transition-all duration-500">
              Contact me
            </button>
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
