import React from 'react';
// components
import About from './components/About';
import Banner from './components/Banner';
import Contact from './components/Contact';
import Nav from './components/Nav';
import Work from './components/Work';

const App = () => {
  return (
    <div
      className=" bg-[#121212] bg-no-repeat bg-cover overflow-hidden"
      // style={{background: '#0e1111'}}
    >
      <Banner />
      <Nav />
      <About />
      <Work />
      <Contact />
      {/* <div className="h-[4000px]"></div> */}
    </div>
  );
};

export default App;
