import {motion} from 'framer-motion';
import React from 'react';
import {useInView} from 'react-intersection-observer';
import Image from '../assets/ibrahim.png';
import {fadeIn} from '../variants';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const skills = [
    'Kotlin',
    'Retrofit',
    'Coroutines',
    'RxJava',
    'Navigation Component',
    'Dagger – Hilt',
  ];

  return (
    <section id="about" className="section h-max lg:items-center" ref={ref}>
      <div className="container mx-auto">
        <h2 className=" font-primary text-[32px] mb-6 tracking-[10%] text-accent after:bg-accent after:h-px lg:after:w-[300px] after:inline-block after:relative after:w-full lg:after:ml-4 after:mb-2">
          About Me
        </h2>
        <div className="flex flex-col gap-y-10 lg:flex-row lg:gap-x-20 lg:gap-y-0 ">
          <motion.div
            variants={fadeIn('right', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{once: false, amount: 0.3}}
            className="flex-1 ">
            <h3 className="h3 mb-4 text-[20px] lg:text-[26px]">
              Hey everyone! 👋 My name is Ibrahim and I am an Android Developer!{' '}
            </h3>
            <p className="mb-6">
              I'm passionate about building amazing apps for Android users. My
              journey into mobile development started back in 2020 when I
              created my first Android app using Java. From there, I fell in
              love with the process of creating immersive mobile experiences and
              have been hooked ever since. Over the years, I've had the
              opportunity to work with an organization and including startup
              Through this experience, I've developed a deep understanding of
              what it takes to create high-quality, user-friendly Android apps.
            </p>
            <p className="mb-6">
              Today, my focus is on building accessible, responsive, and
              inclusive apps that deliver value to users. I work as a
              freelancer, where I use Kotlin to develop apps that solve complex
              problems and delight users.
            </p>
            <p className="">
              Here are a few technologies I’ve been working with recently:
            </p>
            <ul className="grid gap-4 grid-cols-2 grid-rows-3 lg:gap-y-2 mt-[10px] mx-auto">
              {skills.map(skill => (
                <li className="before:content-['○'] before:text-accent before:mr-2 text-[16px]">
                  {skill}
                </li>
              ))}
            </ul>
            {/* <div className="flex flex-row md:row-2 gap-x-6 lg:gap-x-10  mx-auto">
              <div>
                <div className="text-[36px] lg:text-[40px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={2} duration={3} /> : <></>}
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Years of <br />
                  Experience
                </div>
              </div>
              <div>
                <div className="text-[36px] lg:text-[40px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={21} duration={3} /> : <></>}
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Projects <br />
                  Completed
                </div>
              </div>
              <div>
                <div className="text-[36px] lg:text-[40px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={18} duration={3} /> : <></>}
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Satisfied <br />
                  Clients
                </div>
              </div>
            </div> */}
          </motion.div>
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{once: false, amount: 0.3}}
            className=" flex-1 mx-auto select-none  lg:flex">
            {/* <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{once: false, amount: 0.3}}
            className="flex-1 bg-contain bg-no-repeat h-[340px] mix-blend-lighten bg-top sm:hidden"></motion.div> */}
            <div className="group relative inline-block boxShadow h-min">
              <div className="absolute duration-500 h-[220px] w-[220px] lg:h-[300px] lg:w-[300px] rounded-lg border-2 border-accent lg:top-5 lg:left-5 top-4 left-4 group-hover:top-3 group-hover:left-2 group-hover:duration-700  lg:group-hover:top-4 lg:group-hover:left-3  "></div>
              <img
                className=" brightness-[0.4] rounded-lg transition duration-500  h-[220px] w-[220px] lg:h-[300px] lg:w-[300px] group-hover:duration-700  group-hover:filter-none group-hover:relative "
                src={Image}
                alt=""></img>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
