import React, {useEffect, useState} from 'react';
import {BiHomeAlt, BiMenu, BiUser, BiX} from 'react-icons/bi';
import {BsBriefcase, BsChatSquareText} from 'react-icons/bs';
import {useMediaQuery} from 'react-responsive';
import {Link} from 'react-scroll';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({query: `(max-width: 770px)`});
  console.log(isMobile);

  const [navBackground, setNavBackground] = useState('bg-transparent');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 0) {
        setNavBackground('bg-black/40');
      } else {
        setNavBackground('bg-black/20');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isMobile) {
    return (
      <nav className="fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50">
        <div className="container mx-auto">
          <div
            className="w-full bg-black/70 h-[72px] backdrop-blur-2x1
      rounded-full max-w-[460px] mx-auto px-5 flex justify-between items-center text-2x1 text-white/60">
            <Link
              to="home"
              activeClass="activeLink"
              smooth={true}
              spy={true}
              offset={-200}
              className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">
              <BiHomeAlt />
            </Link>
            <Link
              to="about"
              activeClass="activeLink"
              smooth={true}
              spy={true}
              className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">
              <BiUser />
            </Link>
            {/* <Link
              to="services"
              activeClass="active"
              smooth={true}
              spy={true}
              className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">
              <BsClipboardData />
            </Link> */}
            <Link
              to="work"
              activeClass="activeLink"
              smooth={true}
              spy={true}
              className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">
              <BsBriefcase />
            </Link>
            <Link
              to="contact"
              activeClass="activeLink"
              smooth={true}
              spy={true}
              className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center">
              <BsChatSquareText />
            </Link>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav
      className={`fixed  w-full overflow-hidden z-50 fixed top-0 w-full backdrop-blur-2xl ${navBackground} `}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-[70px]">
          <div className="flex items-center">
            <a href="/" className="text-white font-bold text-xl">
              {/* <img src={Logo} alt=""></img> */}
              <h3 className="font-secondary  font-bold uppercase text-[32px] lg:text-[30px] text-gradient"></h3>
              {/* <h3 className="font-secondary font-semibold uppercase text-[32px] lg:text-[34px] text-white">
                GRIM
              </h3> */}
            </a>
          </div>
          <div className="-mr-2 flex items-center md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <BiX className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <BiMenu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          <div
            className="hidden md:flex md:items-center md:w-auto"
            id="mobile-menu">
            <div className="md:flex-grow select-none ">
              <Link
                to="home"
                smooth={true}
                offset={-200}
                spy={true}
                activeClass="activeLink"
                className=" cursor-pointer text-white font-primary font-semibold text-lg mr-8">
                Home
              </Link>
              <Link
                to="about"
                smooth={true}
                spy={true}
                activeClass="activeLink"
                className="cursor-pointer text-white font-primary font-semibold text-lg mr-8">
                About
              </Link>
              {/* <Link
                to="services"
                smooth={true}
                spy={true}
                activeClass="activeLink"
                className="cursor-pointer text-white font-secondary font-semibold text-xl mr-8">
                Services
              </Link> */}
              <Link
                to="work"
                smooth={true}
                spy={true}
                activeClass="activeLink"
                className="cursor-pointer text-white font-primary font-semibold text-lg mr-8">
                Work
              </Link>
              <Link
                to="contact"
                smooth={true}
                spy={true}
                activeClass="activeLink"
                className="cursor-pointer text-white font-primary font-semibold text-lg mr-8">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
