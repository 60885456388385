export const projects = [
  {
    title: 'UI/UX Design',
    name: 'Gaming Stream',
    image: require('../assets/gaming.png'),
    content:
      "This platform is for true gamers.  It's a place where people can watch their favorite games, follow new streamers, and chat.",
  },
  {
    title: 'Yoga App Concept',
    name: 'Aura',
    image: require('../assets/aura.png'),
    content:
      'Aura is the ultimate yoga companion for beginners and advanced practitioners alike. With its intuitive interface, diverse programs, and personalized approach, Aura makes it easy to develop a consistent yoga practice that meets your unique needs and goals.',
  },
  {
    title: 'UI/UX Case Study',
    name: 'Bookclub',
    image: require('../assets/bookclub.png'),
    content:
      'Bookclub is a digital reading app that offers a wide selection of e-books and audiobooks for users to purchase and download directly within the app. With a personalized recommendation system, convenient payment options, high-quality audiobooks, and accessibility features, Bookclub is the ultimate solution for anyone who loves to read or listen to books.',
  },
  {
    title: 'UI Concept',
    name: 'Mortal Kombat',
    image: require('../assets/mortal.png'),
    content:
      'A simple but fresh idea for the Mortal Kombat fandom. Here you can find all the info about your favourite characters, their powers and skills 🦂',
  },
  {
    title: 'UI/UX Design',
    name: 'Game Streaming',
    image: require('../assets/gamestream.png'),
    content:
      'Manage your game library, interact with friends,create games, view stats, news and shop.',
  },
];
